html{
  font-family: 'Lato', sans-serif;
  font-family: 'Vollkorn', serif;
}

@media (min-width: 0px) {
  html{
    font-size: 8px;
  }
}

@media (min-width: 300px) {
  html{
    font-size: 10px;
  }
}

@media (min-width: 576px) {
  html{
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  html{
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  html{
    font-size: 14px;
  }
}

@media (min-width: 1300px) {
  html{
    font-size: 16px;
  }
}

@media (min-width: 1400px) {

}


@media (min-width: 0px) {
  .logo-box-mobile{
    display: flex;
  }

  .hero-grid-logo{
    display: none !important;
  }

  .page-container{
    min-height: 100vh;
    overflow: auto;
  }
}


@media (min-width: 600px) {
  .logo-box-mobile{
    display: none !important;
  }

  .hero-text-grid{
    min-height: 100vh !important;
  }

  .hero-grid-logo{
    display: block !important;
  }

  .page-container{
    height: 100vh;
    overflow: hidden;
  }
}

@media (max-width: 500px) {
  .hero-header{
    font-size: 2.5rem !important;
  }
}

@media (max-width: 478px) {
  .social-btn{
    width: 100%;
    margin-right: 0;
  }
}

